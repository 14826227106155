import {
    Fragment,
    HtmlHTMLAttributes,
    InputHTMLAttributes,
    LabelHTMLAttributes,
    ReactNode,
    useEffect,
    useRef,
    useState,
    forwardRef,
} from 'react';
import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronDownIcon } from '@heroicons/react/solid';

import InputMask from 'react-input-mask';
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input';
import { classNames } from 'src/libs/utils';
import {
    FieldError,
    FieldErrors,
    UseFormRegisterReturn,
} from 'react-hook-form';
import { XIcon } from '@heroicons/react/outline';
import { EyeOffIcon, EyeIcon } from '@heroicons/react/solid';
import { WrapperLetterCounter } from '../letterCounter';
/**
 * Default code size in amazon cognito
 */
const DEFAULT_PHONE_CODE_SIZE = 6;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    registerReactHookInput?: UseFormRegisterReturn;
    error?: string | { message: string } | FieldError | FieldErrors; //ToDo Need fix TS dependency, but today no way to do it.
    icon?: React.ElementType;
    hideErrorMsg?: boolean;
    isPassword?: boolean;
    onClickErase?: () => void;
    padding?: string;
    mask?: string;
}

interface InputLabelProps extends LabelHTMLAttributes<HTMLElement> {
    margin?: string;
}

export const InputLabel = (props: InputLabelProps) => (
    <span
        className={clsx(
            props.margin || 'mb-1',
            'block text-sm font-medium text-gray-700  font-mont leading-[17px]',
        )}
        {...props}
    />
);

const BaseInput = (props: InputProps) => {
    /** The order of props is important, anything above of props would be overridden if props.VALUE is passed */
    const ref = useRef<ReturnType<typeof setTimeout> | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const {
        registerReactHookInput = { name: '', ref: undefined },
        icon,
        type,
        value,
        isPassword,
        padding,
        mask,
        ...otherProps
    } = props;
    const [inputType, setInputType] = useState(type);
    const [inputFocused, setInputFocused] = useState(false);

    useEffect(() => {
        let refLink = registerReactHookInput.ref;
        registerReactHookInput.ref = (ref: any) => {
            inputRef.current = ref;
            refLink && refLink(ref);
        };
    }, [registerReactHookInput]);

    const onClickErase = () => {
        shouldChangeInputFocused(true);
        if (registerReactHookInput?.name) {
            (
                document.querySelector(
                    `input[name="${registerReactHookInput?.name}"]`,
                ) as HTMLInputElement
            ).value = '';
        } else if (props?.id) {
            (
                document.querySelector(`input#${props?.id}`) as HTMLInputElement
            ).value = '';
        } else {
            throw 'Pls add #id for input';
        }
    };

    const onFocus = (e: any) => {
        props.onFocus && props.onFocus(e);
        shouldChangeInputFocused(true);
    };

    const onBlur = (e: any) => {
        props.onBlur && props.onBlur(e);
        shouldChangeInputFocused(false);
    };

    const onClickShowPassword = () => {
        shouldChangeInputFocused(true);
        setInputType(inputType === 'password' ? 'text' : 'password');
    };

    const shouldChangeInputFocused = (value: boolean) => {
        ref.current && clearTimeout(ref.current);
        if (!value) {
            ref.current = setTimeout(() => {
                setInputFocused(false);
            }, 200);
        } else {
            !!inputRef.current &&
                !!inputRef.current.focus &&
                inputRef.current?.focus();
            setInputFocused(true);
        }
    };

    /**
     * FYI about 'numberInputOnWheelPreventChange'
        This is hack. When you put the focus in the number input, 
        and scroll up or scroll down the value in the input field 
        changes in up or in down with the step 0,01. 
        This is known browser problem, the proposal of solving this 
        problem is add onScroll handler for input and when action is fired 
        make lost focus from number input and after loosing 
        return the focus to number input. As for me it is very annoying. 
        That is why I make calibration for new input value, 
        and for user it looks as value is not changed.

        @TODO: Fix edge case when step is 0
     */
    const numberInputOnWheelPreventChange =
        (type: string | undefined) => (e: any) => {
            if (type === 'number') {
                if (e.deltaY > 0) {
                    e.target.value =
                        Number(e.target.value) +
                        Number(e.currentTarget.step || 1);
                } else if (e.deltaY < 0) {
                    e.target.value =
                        Number(e.target.value) -
                        Number(e.currentTarget.step || 1);
                }
            }
        };

    return (
        <div className="relative w-full">
            {!!props.icon ? (
                <props.icon className="w-5 h-5 top-[11px] lg:top-[8px] left-1.5 absolute text" />
            ) : null}
            {mask ? (
                //@ts-ignore
                <InputMask
                    mask={mask}
                    {...otherProps}
                    {...registerReactHookInput}
                    type={inputType}
                    value={value}
                    className={clsx(
                        padding || 'p-2.5',
                        'font-mont sm:text-sm appearance-none block w-full ',
                        'border border-gray-300 rounded-md shadow-sm',
                        'placeholder-gray-400 focus:outline-none onfocus-ring-blue focus:border-indigo-500',
                        'disabled:bg-grayColor disabled:text-[#A5ABBA]',
                        props.className,
                        {
                            'border-redErr onfocus-ring-error focus:border-redErr':
                                props.error,
                            'pr-14': isPassword && inputFocused,
                            'pr-10': inputFocused || isPassword,
                        },
                    )}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onWheel={numberInputOnWheelPreventChange(inputType)}
                />
            ) : (
                <input
                    {...otherProps}
                    {...registerReactHookInput}
                    type={inputType}
                    value={value}
                    className={clsx(
                        padding || 'p-2.5',
                        'font-mont sm:text-sm appearance-none block w-full ',
                        'border border-gray-300 rounded-md shadow-sm',
                        'placeholder-gray-400 focus:outline-none onfocus-ring-blue focus:border-indigo-500',
                        'disabled:bg-grayColor disabled:text-[#A5ABBA]',
                        props.className,
                        {
                            'border-redErr onfocus-ring-error focus:border-redErr':
                                props.error,
                            'pr-14': isPassword && inputFocused,
                            'pr-10': inputFocused || isPassword,
                        },
                    )}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onWheel={numberInputOnWheelPreventChange(inputType)}
                />
            )}

            {inputFocused && !props.readOnly && !props.disabled ? (
                <div
                    className="absolute top-[15px] right-4 cursor-pointer"
                    onClick={onClickErase}
                >
                    <XIcon width={12} height={12} />
                </div>
            ) : null}

            {isPassword ? (
                <div
                    className="absolute top-[15px] right-4 cursor-pointer"
                    onClick={onClickShowPassword}
                    style={{ marginRight: inputFocused ? 14 : 0 }}
                >
                    {inputType === 'password' ? (
                        <EyeIcon width={12} height={12} className="mr-[2px]" />
                    ) : (
                        <EyeOffIcon
                            width={12}
                            height={12}
                            className="mr-[2px]"
                        />
                    )}
                </div>
            ) : null}

            {props.error && (
                <span className="font-mont text-redErr mb-8 text-sm w-[30rem]">
                    {typeof props.error === 'object'
                        ? props.error?.message
                        : props.error}
                </span>
            )}
        </div>
    );
};

export const TextInput = (props: InputProps) => {
    /** The order of props is important, anything above of props would be overridden if props.VALUE is passed */
    const { maxLength, ...otherProps } = props;
    return (
        <WrapperLetterCounter
            value={props.value}
            maxLength={maxLength}
            shouldHidden={!!props.error}
        >
            <BaseInput type="text" {...otherProps} />
        </WrapperLetterCounter>
    );
};

export const NumberInput = (props: InputProps) => {
    /** The order of props is important, anything above of props would be overridden if props.VALUE is passed */
    return <BaseInput type="number" {...props} />;
};

export const EmailInput = (props: InputProps) => {
    /** The order of props is important, anything above of props would be overridden if props.VALUE is passed */
    const { maxLength, ...otherProps } = props;

    return (
        <WrapperLetterCounter
            value={props.value}
            maxLength={maxLength}
            shouldHidden={!!props.error}
        >
            <BaseInput type="text" {...otherProps} />
        </WrapperLetterCounter>
    );
};

export const PasswordInput = (props: InputProps) => {
    /** The order of props is important, anything above of props would be overridden if props.VALUE is passed */
    const { maxLength, ...otherProps } = props;

    return (
        <WrapperLetterCounter
            value={props.value}
            maxLength={maxLength}
            shouldHidden={!!props.error}
        >
            <BaseInput type="password" isPassword {...otherProps} />
        </WrapperLetterCounter>
    );
};

export const Textarea = (props: any) => {
    /** The order of props is important, anything above of props would be overridden if props.VALUE is passed */
    const { registerReactHookInput = {}, maxLength, ...otherProps } = props;
    return (
        <WrapperLetterCounter
            value={props.value}
            maxLength={maxLength}
            shouldHidden={!!props.error}
        >
            <textarea
                {...otherProps}
                {...registerReactHookInput}
                className={clsx(
                    'font-mont appearance-none block h-[6rem] px-2.5 py-2.5 sm:text-sm',
                    'border border-gray-300 rounded-md shadow-sm placeholder-gray-400',
                    'focus:outline-none onfocus-ring-blue focus:border-indigo-500',
                    props.className,
                    {
                        'border-redErr onfocus-ring-error focus:border-redErr':
                            props.error,
                    },
                )}
            />
            {props.error && (
                <div className="font-mont text-redErr my-2.5 text-sm w-full max-w-[36rem]">
                    {typeof props.error === 'object'
                        ? props.error?.message
                        : props.error}
                </div>
            )}
        </WrapperLetterCounter>
    );
};

export const Checkbox = (props: InputProps) => {
    /** The order of props is important, anything above of props would be overridden if props.VALUE is passed */
    const {
        registerReactHookInput = {},
        error,
        hideErrorMsg,
        ...otherProps
    } = props;

    return (
        <div className="relative">
            <input
                {...otherProps}
                {...registerReactHookInput}
                type="checkbox"
                className={clsx(
                    'h-4 w-4 text-indigo-600 onfocus-ring-blue border-gray-300 rounded',
                    {
                        'border-redErr onfocus-ring-error focus:border-redErr':
                            !!props.error,
                    },
                )}
            />
            {props.error && !hideErrorMsg && (
                <span className="font-mont text-redErr mb-8 text-sm w-[30rem] absolute top-[48px] left-0">
                    {typeof props.error === 'object'
                        ? //@ts-ignore
                          props.error?.message
                        : props.error}
                </span>
            )}
        </div>
    );
};

export const SelectInputButton = (
    props: HtmlHTMLAttributes<any> & { className?: string },
) => (
    <Listbox.Button
        className={clsx(
            'inline-flex justify-between rounded-md border border-gray-300 shadow-sm',
            'w-full p-2.5 bg-white hover:bg-gray-50',
            'font-normal whitespace-nowrap sm:text-sm font-mont',
            'focus:outline-none onfocus-ring-blue',
        )}
        style={{ borderStyle: 'solid' }}
    >
        <div
            className={clsx(
                'text-base sm:text-sm',
                props.className || 'font-normal',
                {
                    'text-gray-400': !props.children,
                },
            )}
        >
            {props.children || 'Select item'}
        </div>
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
    </Listbox.Button>
);

type SelectOption = {
    value: string;
    disabled?: boolean;
    label?: string;
};

const ACTIVE_SELECT_OPTION_CLASS = 'bg-gray-100 text-gray-900';
const INACTIVE_SELECT_OPTION_CLASS = 'text-gray-700';

export const SelectInput = forwardRef(
    (
        props: InputHTMLAttributes<any> & {
            error?: string;
            label?: ReactNode;
            options: Array<SelectOption>;
            btnClassName?: string;
        },
        ref,
    ) => {
        const isActive = (option: SelectOption) => option.value === props.value;
        const selectedOption = props.options.find(
            (option) => option.value === props.value,
        ) as SelectOption;
        return (
            <>
                <Listbox
                    onChange={props.onChange as any}
                    value={props.value}
                    disabled={props.disabled}
                >
                    {props.label && <InputLabel>{props.label}</InputLabel>}
                    <SelectInputButton className={props.btnClassName}>
                        {selectedOption?.label || selectedOption?.value}
                    </SelectInputButton>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Listbox.Options
                            className={clsx(`
                                    origin-top-right absolute right-[0.1rem] mt-0 shadow-lg 
                                    bg-white ring-1 ring-black ring-opacity-5 focus:outline-none 
                                    w-full z-10 max-h-[160px] overflow-y-auto rounded-md
                                `)}
                        >
                            {props.options.map((option) => (
                                <Listbox.Option
                                    key={option.value}
                                    value={option.value}
                                    disabled={option.disabled}
                                    className={({ active }) =>
                                        classNames(
                                            'block cursor-pointer px-3.5 py-2 text-sm hover:bg-grayColor',
                                            isActive(option)
                                                ? ACTIVE_SELECT_OPTION_CLASS
                                                : INACTIVE_SELECT_OPTION_CLASS,
                                            active
                                                ? 'bg-gray-300'
                                                : INACTIVE_SELECT_OPTION_CLASS,
                                        )
                                    }
                                >
                                    {option.label || option.value}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </Listbox>
                {props.error && (
                    <span className="font-mont text-redErr mb-8 text-sm w-[30rem]">
                        {props.error}
                    </span>
                )}
            </>
        );
    },
);

export const PhoneInput = (props: InputProps) => {
    /** The order of props is important, anything above of props would be overridden if props.VALUE is passed */
    return <BaseInput type="tel" autoComplete="tel-national" {...props} />;
};

export const CodeInput = (prop: ReactCodeInputProps) => {
    const props: ReactCodeInputProps = {
        // className: reactCodeInput,
        inputStyle: {
            fontFamily: 'monospace',
            margin: '0 18px 18px 0',
            MozAppearance: 'textfield',
            width: '56px',
            borderRadius: '5px',
            fontSize: '14px',
            height: '43px',
            paddingLeft: '7px',
            textAlign: 'center',
            color: 'black',
            border: '1px solid rgba(0, 0, 0, 0.1)',
        },
        ...prop,
    };
    return (
        <ReactCodeInput
            type="text"
            fields={DEFAULT_PHONE_CODE_SIZE}
            {...props}
        />
    );
};
interface IExtendedInputProps extends InputProps {
    fontWeight?: string;
    textColor?: string;
    textSize?: string;
    paddingX?: string;
    paddingY?: string;
    margin?: string;
    label: string;
}

export const FileInput = (props: IExtendedInputProps) => {
    const {
        fontWeight,
        textColor,
        textSize,
        paddingX,
        paddingY,
        margin,
        label,
        registerReactHookInput = {},
        icon,
        type,
        error,
        ...otherProps
    } = props;

    return (
        <div className="">
            <label
                htmlFor="file-upload"
                className={clsx(
                    fontWeight || 'font-bold',
                    textColor || 'text-blue',
                    textSize || 'text-sm',
                    paddingX || 'px-[26px]',
                    paddingY || 'py-2',
                    margin || '',
                    'font-mont whitespace-nowrap leading-[17px] focus:outline-none onfocus-ring-blue',
                    'bg-outlinedBtnBg hover:bg-outlinedBtnBgHov disabled:bg-outlinedBtnBgDisabled',
                    'border-2 border-blue',
                    'flex items-center justify-center rounded-md shadow-sm',
                    'relative cursor-pointer',
                )}
            >
                <span className="cursor-pointer">{label || 'Select'}</span>
                <input
                    {...otherProps}
                    {...registerReactHookInput}
                    type="file"
                    className="w-full h-full absolute opacity-0 top-0 left-0"
                />
            </label>
            {props.error && (
                <span className="font-mont text-redErr mb-8 pt-2 text-sm w-[30rem] absolute">
                    {typeof props.error === 'object'
                        ? //@ts-ignore
                          props.error?.message
                        : props.error}
                </span>
            )}
        </div>
    );
};

type TRadioButtonProps = {
    selected: boolean;
    onClick: () => void;
};

export const PseudoRadioButton = ({ selected, onClick }: TRadioButtonProps) => {
    return (
        <div
            onClick={onClick}
            className="flex justify-center items-center w-5 h-5 rounded-xl border-2 border-black"
        >
            {selected ? (
                <div className="w-2.5 h-2.5 rounded-md bg-black" />
            ) : null}
        </div>
    );
};
