type TProps = {
    // input value
    value?: string;
    // number of max chars which may contain input value
    maxLength?: number;
    //direct control of visibility
    shouldHidden?: boolean;
};

type TWrapperProps = {
    // input value
    value?: string | number | readonly string[] | undefined;
    // number of max chars which may contain input value
    maxLength?: number;
    // wrapped input element
    children: JSX.Element | JSX.Element[] | null;
    //direct control of visibility
    shouldHidden?: boolean;
};

export const WrapperLetterCounter = (props: TWrapperProps) => {
    const { children, value, ...otherProps } = props;
    return (
        <div className="relative w-full">
            {children}
            <div className="absolute right-0 bottom-[-34px] font-mont my-2.5 text-sm text-grayText">
                <LetterCounter
                    value={value ? value.toString() : ''}
                    {...otherProps}
                />
            </div>
        </div>
    );
};

const LetterCounter = ({ value, maxLength, shouldHidden }: TProps) => {
    const length = value ? value.length : 0;
    return maxLength && !shouldHidden && maxLength >= length ? (
        <div className="text-sm">
            {length}/{maxLength}
        </div>
    ) : null;
};

export default LetterCounter;
