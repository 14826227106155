import React from 'react';
import clsx from 'clsx';
import { ButtonProps } from 'src/libs/types/forms';

export const PrimaryButton = React.forwardRef(function MainButton(
    props: ButtonProps,
    ref?: React.LegacyRef<HTMLButtonElement>,
) {
    const {
        fontWeight,
        textColor,
        textSize,
        paddingX,
        paddingY,
        margin,
        children,
        label,
        bgColor,
        type,
        width,
        ...otherProps
    } = props;
    return (
        <button
            type={type || 'submit'}
            {...otherProps}
            className={clsx(
                fontWeight || 'font-bold',
                textColor || 'text-white',
                textSize || 'text-sm',
                paddingX || 'px-[26px]',
                paddingY || 'py-4',
                margin || 'mb-[2.0625rem]',
                bgColor ||
                    'bg-primaryBtnBg hover:bg-primaryBtnBgHov disabled:bg-primaryBtnBgDisabled',
                'font-mont whitespace-nowrap focus:outline-none onfocus-ring-blue leading-4',
                'border border border-transparent',
                'flex items-center justify-center rounded-md shadow-sm',
                props.className || '',
                width,
            )}
            ref={ref}
        >
            {label || children}
        </button>
    );
});

export default PrimaryButton;
